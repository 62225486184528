import logFetch from '../../../utils/logFetch';
import { Thread } from '../../../types/thread';
import logger from '../../../constants/logger';
import { INTERCOM_URL } from '../../../constants/defaults';

const loadIntercomById = async (id: string): Promise<{ status: number, result?: { thread: Thread } }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET intercom by id',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
        };

        const response = await logFetch(`${INTERCOM_URL}thread/${id}?withLastMessage=true`, requestInit, logOptions);

        if (response.status === 200) {
            const result = await response.json();
            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to get intercom by id',
            section: 'api/intercom/get/loadIntercomById.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { loadIntercomById };
