import os from 'os';
import VERSION from './version';
import { ChaynsLogger as ClientLogger } from 'chayns-logger';
import { isDEVELOPMENT, isQA } from './environments';
import { IGNORE_LIST, IGNORE_MESSAGE, IGNORE_SECTION } from './logger-exceptions';

let usedLogger;
let isClientSide = null;

const logger = () => {
    if (typeof window === 'undefined' && isClientSide !== false) {
        const { ChaynsLogger, ChaynsLoggerProtocol } = require('@chayns/logger-node');
        isClientSide = false;

        usedLogger = new ChaynsLogger({
            useDevServer: isQA || isDEVELOPMENT,
            applicationName: 'chaynsDeNode',
            team: 'backend',
            throttleTime: 500,
            machineName: os.hostname(),
            version: `${VERSION}_${process.env.NEXT_PUBLIC_BUILD_ENV ?? 'dev'}`,
        });

        usedLogger.info({
            message: 'start server',
        });

        usedLogger.info({
            message: 'start server - info',
            data: {
                build_env: process.env.NEXT_PUBLIC_BUILD_ENV,
                node_env: process.env.NODE_ENV,
                version: VERSION,
            },
        });
    } else if (typeof window !== 'undefined' && !isClientSide) {
        // if change to client side, init chayns logger
        usedLogger = new ClientLogger({
            applicationUid: '19850794-62dc-4aba-9bda-83ff613b631e',
            overrideOnError: true,
            overrideConsoleError: true,
            throttleTime: 100,
            useDevServer: isDEVELOPMENT || isQA,
            version: VERSION,
            middleware: (payload) => {
                if (IGNORE_MESSAGE.includes(payload?.message)) {
                    return false;
                }

                if (IGNORE_SECTION.some((section) => payload?.section?.startsWith(section))) {
                    return false;
                }

                // Remove error logs for android app script injection
                if (payload?.ex?.stackTrace?.includes('<anonymous>')) {
                    return false;
                }

                // Remove error logs from chayns js
                if (payload?.ex?.stackTrace?.includes('https://api.chayns-static.space/js')) {
                    return false;
                }

                const lowerUserAgent = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase();
                return !IGNORE_LIST.some((bot) => lowerUserAgent.indexOf(bot) > -1);
            },
        });

        usedLogger.info({
            message: 'load user',
            data: {
                build_env: process.env.NEXT_PUBLIC_BUILD_ENV,
                node_env: process.env.NODE_ENV,
                version: VERSION,
            },
        });

        isClientSide = true;
    }

    return usedLogger;
};

export default logger;
