import logFetch from '../../../utils/logFetch';
import logger from '../../../constants/logger';
import { INTERCOM_URL } from '../../../constants/defaults';

const patchThread = async (threadId: string, memberId: number | string, key: string, body: unknown): Promise<{
    status: number
}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'PATCH intercom thread',
        };

        const requestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            body: JSON.stringify(body),
        };

        const response = await logFetch(`${INTERCOM_URL}thread/${threadId}/member/${memberId}/${key}`, requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to patch thread',
            section: 'api/intercom/patch/patchThread.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { patchThread };
