import logFetch from '../../../utils/logFetch';
import { Thread } from '../../../types/thread';
import logger from '../../../constants/logger';
import { INTERCOM_URL } from '../../../constants/defaults';

const loadIntercomClient = async (lastFetch: string, take: number): Promise<{ status: number, result?: { threads: Array<Thread> }}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET intercom client',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
        };

        const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : chayns.env.user.id;

        const params = lastFetch ? `?date=${lastFetch}` : '?take=' + take;

        const requestOnlyUnread = lastFetch ? '' : '/unreadThreads';

        const response = await logFetch(`${INTERCOM_URL}user/${userId}${requestOnlyUnread}${params}`, requestInit, logOptions);

        if (response.status === 200) {
            const result = await response.json();
            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to get intercom client',
            section: 'api/intercom/get/loadIntercomClient.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { loadIntercomClient };
